import React, { FunctionComponent, useState } from 'react'
import { Input, InputProps, useTheme } from '@chakra-ui/react'

interface IStripeInput extends InputProps {
  hasFocus?: boolean
  showIcon?: boolean
}

export const StripeInput: FunctionComponent<IStripeInput> = ({
  as,
  hasFocus,
  id,
  isInvalid,
  placeholder,
  showIcon = false,
  ...rest
}) => {
  return (
    <Input
      as={as}
      borderColor={isInvalid ? '#e53e3e' : undefined}
      boxShadow={
        hasFocus
          ? `0 0 0 3px #ffb500`
          : isInvalid
          ? `0 0 0 1px #e53e3e`
          : 'none'
      }
      display="auto"
      options={{
        placeholder,
        showIcon: showIcon ? showIcon : undefined,
        style: {
          base: {
            color: '#424770',
            fontFamily:
              '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", "Ubuntu"',
            fontSize: '16px',
            lineHeight: '2.4em',
            '::placeholder': {
              color: '#CBD5E0',
            },
          },
          invalid: {
            color: '#9e2146',
          },
        },
      }}
      pr={2}
      {...rest}
    />
  )
}

import Amplify, { API } from 'aws-amplify'
import { CustomerOptions, PaymentIntent } from '@stripe/stripe-js'

export const handlePayment = async ({
  addressLine1,
  addressLine2,
  amount,
  city,
  email,
  fname,
  lname,
  payment_method_id,
  postalCode,
}: any): Promise<PaymentIntent & CustomerOptions> => {
  return await API.post(`payment`, '/payment', {
    body: {
      amount,
      addressLine1,
      addressLine2,
      city,
      email,
      fname,
      lname,
      payment_method_id,
      postalCode,
    },
  })
}

export const handleConfirm = async ({
  payment_intent_id,
}: any): Promise<PaymentIntent> => {
  return await API.post(`payment`, '/confirm', {
    body: {
      payment_intent_id,
      returnUrl: `${window.location.origin}/checkout/auth-complete/`,
    },
  })
}

// export const handleIntent = async ({
//   amount,
//   customer,
//   payment_method,
// }: any): Promise<PaymentIntent> => {
//   const resp = await apiClient.post<PaymentIntent>(
//     '/intent',
//     {
//       amount,
//       customer,
//       payment_method,
//     },
//     {
//       validateStatus: (statusCode_1: number) => statusCode_1 === 200,
//     }
//   )
//   return resp.data
// }

// export const handleCustomer = async ({
//   addressLine1,
//   addressLine2,
//   city,
//   email,
//   fname,
//   lname,
//   payment_method,
//   postalCode,
// }: any): Promise<{}> => {
//   const resp = await apiClient.post<{}>(
//     '/customer',
//     {
//       addressLine1,
//       addressLine2,
//       city,
//       email,
//       fname,
//       lname,
//       payment_method,
//       postalCode,
//     },
//     {
//       validateStatus: (statusCode_1: number) => statusCode_1 === 200,
//     }
//   )
//   return resp.data
// }

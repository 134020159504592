import React, { FunctionComponent } from 'react'
import { navigate, PageProps } from 'gatsby'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'

import { Layout } from '../components/layout'
import { Cart } from '../components/cart'
import { Checkout } from '../components/checkout'
import { SEO } from '../components/seo'
import { useDisclosure } from '@chakra-ui/hooks'
import { useAppState } from '../components/context'
import { Button, Collapse, Container, Flex, Grid } from '@chakra-ui/react'

const stripePromise = loadStripe(`${process.env.GATSBY_STRIPE_KEY}`)

const CheckoutPage: FunctionComponent<PageProps> = () => {
  const { isOpen, onOpen } = useDisclosure()
  const { cart, setCart } = useAppState()

  return (
    <Layout hasCart={false} hasNav={false}>
      <SEO title="Checkout" />
      <Container maxW="4xl">
        <Grid gridGap="8">
          <Flex justifyContent="center" mt="8">
            <Grid gridGap={4} width="100%">
              <Cart hasDiscount={true} isDisabled={isOpen} />
              {cart?.contents?.itemCount === 0 ? (
                <Button
                  color="msuGreen"
                  colorScheme="aqua"
                  justifySelf="flex-end"
                  onClick={() => {
                    navigate(`/products`)
                  }}
                  width="xs"
                >
                  View Products
                </Button>
              ) : !isOpen ? (
                <Button
                  color="msuGreen.500"
                  colorScheme="aqua"
                  justifySelf="flex-end"
                  mt="8"
                  onClick={onOpen}
                  width="xs"
                >
                  Add Payment details
                </Button>
              ) : null}
            </Grid>
          </Flex>

          <Elements stripe={stripePromise}>
            <Collapse in={isOpen}>
              {cart?.contents?.itemCount !== 0 ? <Checkout /> : null}
            </Collapse>
          </Elements>
        </Grid>
      </Container>
    </Layout>
  )
}

export default CheckoutPage

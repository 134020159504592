import { gql } from '@apollo/client'

export const CHECKOUT = gql`
  mutation Checkout($input: CheckoutInput!) {
    checkout(input: $input) {
      order {
        billing {
          email
        }
        databaseId
        discountTotal
        orderNumber
        paymentMethod
        paymentMethodTitle
        subtotal
        total
        lineItems {
          nodes {
            quantity
            subtotal
            total
            product {
              name
              sku
              databaseId
              ... on SimpleProduct {
                id
                name
                price
                onSale
                salePrice
                regularPrice
                stockQuantity
              }
              courseDetails {
                sessions {
                  duration
                  startTime
                }
                courseType {
                  ... on Course {
                    id
                    slug
                    title
                  }
                }
                instructor {
                  ... on Instructor {
                    id
                    title
                  }
                }
                presentationMethod
                locationShortname
              }
            }
          }
        }
      }
    }
  }
`

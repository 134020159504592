import React, { FunctionComponent, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { boolean, number, object, string } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { PaymentIntent, PaymentMethod } from '@stripe/stripe-js'
import { navigate, Link } from 'gatsby'
import {
  CardElement,
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js'
import {
  Button,
  Checkbox,
  CheckboxGroup,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  Heading,
  Icon,
  Input,
  Text,
  useToast,
} from '@chakra-ui/react'
import { useMutation } from '@apollo/client'
import { WpOrder } from '../../../graphql-types'
import { CHECKOUT } from '../../graphql/mutation'
import { useAppState } from '../context'
import { handleConfirm, handlePayment } from '../../api/payment-client'
import { StripeInput } from '../stripeInput'
import stripeImg from '../../images/Powered by Stripe - blurple.svg'
import { BiLock } from 'react-icons/bi'

const schema = object().shape({
  fname: string()
    .matches(/^[A-Za-z-' ]*$/, {
      message: 'Please enter letters only, no special characters or digits',
      excludeEmptyString: false,
    })
    .required('Please enter your first name'),
  lname: string()
    .matches(/^[A-Za-z-' ]*$/, {
      message: 'Please enter letters only, no special characters or digits',
      excludeEmptyString: false,
    })
    .required('Please enter your last name'),
  email: string().email().required('Please enter your email address'),
  addressLine1: string().required('Please enter your address'),
  addressLine2: string(),
  city: string().required('Please enter your city'),
  postalCode: string().required('Please enter your post code'),
  cardNumber: boolean().oneOf([true], 'Please enter your card number'),
  cardExpiry: boolean().oneOf([true], 'Please enter your card expiration date'),
  cardCvc: boolean().oneOf([true], 'Please enter your card security code'),
  terms: boolean().oneOf([true], 'Please accept the terms and conditions'),
})

const initialValues = {
  fname: '',
  lname: '',
  email: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  postalCode: '',
  cardNumber: false,
  cardExpiry: false,
  cardCvc: false,
  terms: false,
}

interface ICheckoutInput {
  clientMutationId: string
  paymentMethod: string
  shippingMethod: string
  billing: {
    firstName: string
    lastName: string
    address1: string
    address2: string
    city: string
    postcode: string
    email: string
  }
  metaData: [
    {
      key: `_stripe_intent_id`
      value: string
    },
    {
      key: `_stripe_customer_id`
      value: string
    }
  ]
}

export const Checkout: FunctionComponent = () => {
  const {
    clearErrors,
    formState: { errors, isValid, isSubmitting },
    handleSubmit,
    register,
    setError,
    setValue,
  } = useForm({
    mode: 'onTouched',
    shouldFocusError: true,
    resolver: yupResolver(schema),
    defaultValues: initialValues,
  })

  const stripe = useStripe()
  const elements = useElements()
  const toast = useToast()
  const { cart, setCart } = useAppState()
  const [order, setOrder] = useState<WpOrder | null>(null)
  const [stripeFocus, setStripeFocus] = useState(null)
  const [paymentIntentClientSecret, setPaymentIntentClientSecret] = useState('')
  const [url3dCheck, setUrl3dCheck] = useState('')

  const [checkout] = useMutation<{
    checkout: { order: WpOrder }
  }>(CHECKOUT, {
    async onCompleted({ checkout }) {
      setOrder(checkout.order)
    },
    onError(error) {
      toast({
        title: 'Error',
        description: 'There was an error with your checkout',
        status: 'error',
      })
      console.error(error)
    },
  })

  const onSubmit = async (values: any): Promise<void> => {
    const {
      fname,
      lname,
      email,
      addressLine1,
      addressLine2,
      city,
      postalCode,
    } = values
    const totalAsNumber: number = cart?.total
      ? parseInt(cart?.total?.replace(/[^0-9]+/g, ''))
      : 0

    if (totalAsNumber == 0) {
      throw Error(`Cart total cannot be zero`)
    }

    try {
      if (process.env.NODE_ENV !== 'development') {
        const paymentMethod = (await handleStripe(values)) as PaymentMethod
        var { customer, client_secret, id } = await handlePayment({
          addressLine1,
          addressLine2,
          amount: totalAsNumber,
          city,
          email,
          fname,
          lname,
          payment_method_id: paymentMethod.id,
          postalCode,
        })
        setPaymentIntentClientSecret(client_secret || '')
        await checkout({
          variables: {
            input: {
              clientMutationId: 'Checkout',
              paymentMethod: 'stripe',
              shippingMethod: 'Flat rate',
              billing: {
                firstName: fname,
                lastName: lname,
                address1: addressLine1,
                address2: addressLine2,
                city: city,
                postcode: postalCode,
                email: email,
              },
              metaData: [
                {
                  key: `_stripe_intent_id`,
                  value: id,
                },
                {
                  key: `_stripe_customer_id`,
                  value: customer,
                },
              ],
            },
          },
        })
        const paymentIntent = await handleConfirm({
          payment_intent_id: id,
        })
        if (paymentIntent.status === 'requires_action') {
          setUrl3dCheck(paymentIntent.next_action?.redirect_to_url?.url || '')
        } else {
          order && handleSuccessfulCheckout(order)
        }
      } else {
        await checkout({
          variables: {
            input: {
              clientMutationId: 'Checkout',
              paymentMethod: 'stripe',
              shippingMethod: 'Flat rate',
              billing: {
                firstName: fname,
                lastName: lname,
                address1: addressLine1,
                address2: addressLine2,
                city: city,
                postcode: postalCode,
                email: email,
              },
              metaData: [
                {
                  key: `_stripe_intent_id`,
                  value: '',
                },
                {
                  key: `_stripe_customer_id`,
                  value: '',
                },
              ],
            },
          },
        })
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: 'There was an error with your checkout',
        status: 'error',
      })
      console.error(error)
    }
  }

  async function handleStripe({
    fname,
    lname,
    email,
    addressLine1,
    addressLine2,
    city,
    postalCode,
  }: any): Promise<PaymentMethod | Error> {
    if (!stripe || !elements) {
      throw Error(`stripe or elements undefined`)
    }

    const card = elements.getElement(CardNumberElement)

    if (!card) {
      throw Error(`Card elements not found`)
    }

    const { paymentMethod, error: paymentMethodError } =
      await stripe.createPaymentMethod({
        card,
        type: 'card',
        billing_details: {
          name: `${fname} ${lname}`,
          email,
          address: {
            line1: addressLine1,
            line2: addressLine2,
            city,
            postal_code: postalCode,
          },
        },
      })

    if (paymentMethodError || !paymentMethod) {
      toast({
        title: 'Error',
        description:
          paymentMethodError?.message ||
          `Unknown error generating payment method.`,
        status: 'error',
      })
      throw Error(
        paymentMethodError?.message ||
          `Unknown error generating payment method.`
      )
    }

    return paymentMethod
  }

  const handle3DSComplete = () => {
    if (!stripe) {
      throw Error(`stripe undefined`)
    }
    stripe
      .retrievePaymentIntent(paymentIntentClientSecret)
      .then(function (result) {
        if (result.error) {
          toast({
            title: 'Error',
            description:
              result.error.message || `Unknown error checking payment method.`,
            status: 'error',
          })
          setUrl3dCheck('')
        } else {
          if (result.paymentIntent.status === 'succeeded') {
            order && handleSuccessfulCheckout(order)
          } else if (
            result.paymentIntent.status === 'requires_payment_method'
          ) {
            toast({
              title: 'Error',
              description: `Card verification failed, plese use an alternative payment method`,
              status: 'error',
            })
            setUrl3dCheck('')
          }
        }
      })
  }

  const handleWindowMessage = (event: MessageEvent) => {
    if (event.data === '3DS-authentication-complete') {
      console.log('3DS complete')
      handle3DSComplete()
    }
  }

  useEffect(() => {
    window.addEventListener('message', handleWindowMessage)
    return () => {
      window.removeEventListener('message', handleWindowMessage)
    }
  }, [handleWindowMessage])

  function handleSuccessfulCheckout(order: WpOrder): void {
    localStorage.removeItem('woo-session')
    setCart(undefined)
    navigate('/checkout/order-received', { state: order })
  }

  const onStripeBlur = () => {
    setStripeFocus(null)
  }

  const onStripeChange = (event: any) => {
    setValue(event.elementType, event.complete, { shouldValidate: false })
    if (event.error) {
      setError(event.elementType, {
        type: 'manual',
        message: event.error.message,
      })
    } else {
      clearErrors(event.elementType)
    }
  }

  const onStripeFocus = (event: any) => {
    setStripeFocus(event.elementType)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {url3dCheck ? (
        <Flex justifyContent="center">
          <iframe width="390" height="400" src={url3dCheck} />
        </Flex>
      ) : (
        <Flex justifyContent="center">
          <Grid gridGap={4} maxWidth="xl" width="100%">
            <Heading mt={8} size="lg">
              Your Details
            </Heading>
            <Grid gridGap="4" gridTemplateColumns="1fr 1fr">
              <FormControl isInvalid={!!errors.fname}>
                <FormLabel>First Name</FormLabel>
                <Input
                  isDisabled={isSubmitting}
                  type="text"
                  {...register('fname')}
                />
                <FormErrorMessage>{errors.fname?.message}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={!!errors.lname}>
                <FormLabel>Last Name</FormLabel>
                <Input
                  isDisabled={isSubmitting}
                  type="text"
                  {...register('lname')}
                />
                <FormErrorMessage>{errors.lname?.message}</FormErrorMessage>
              </FormControl>
            </Grid>
            <FormControl isInvalid={!!errors.email}>
              <FormLabel>Email</FormLabel>
              <Input
                isDisabled={isSubmitting}
                type="text"
                {...register('email')}
              />
              <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
            </FormControl>

            <Heading mt={8} size="lg">
              Billing Address
            </Heading>
            <FormControl isInvalid={!!errors.addressLine1}>
              <FormLabel>Address Line 1</FormLabel>
              <Input
                isDisabled={isSubmitting}
                type="text"
                {...register('addressLine1')}
              />
              <FormErrorMessage>
                {errors.addressLine1?.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.addressLine2}>
              <FormLabel>Address Line 2</FormLabel>
              <Input
                isDisabled={isSubmitting}
                type="text"
                {...register('addressLine2')}
              />
              <FormErrorMessage>
                {errors.addressLine2?.message}
              </FormErrorMessage>
            </FormControl>
            <Grid gridGap="4" gridTemplateColumns="1fr 1fr">
              <FormControl isInvalid={!!errors.city}>
                <FormLabel>City</FormLabel>
                <Input
                  isDisabled={isSubmitting}
                  type="text"
                  {...register('city')}
                />
                <FormErrorMessage>{errors.city?.message}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={!!errors.postalCode}>
                <FormLabel>Postcode</FormLabel>
                <Input
                  isDisabled={isSubmitting}
                  type="text"
                  {...register('postalCode')}
                />
                <FormErrorMessage>
                  {errors.postalCode?.message}
                </FormErrorMessage>
              </FormControl>
            </Grid>

            <Heading mt={8} size="lg">
              Payment
            </Heading>
            <FormControl isInvalid={!!errors.cardNumber}>
              <FormLabel>Card Number</FormLabel>
              <StripeInput
                as={CardNumberElement}
                isDisabled={isSubmitting}
                isInvalid={!!errors.cardNumber}
                hasFocus={stripeFocus === 'cardNumber'}
                onBlur={onStripeBlur}
                onChange={onStripeChange}
                onFocus={onStripeFocus}
                showIcon={true}
              />
              <FormErrorMessage>{errors.cardNumber?.message}</FormErrorMessage>
            </FormControl>
            <Grid gridGap={4} gridTemplateColumns="1fr 1fr">
              <FormControl isInvalid={!!errors.cardExpiry}>
                <FormLabel>Expiration date</FormLabel>
                <StripeInput
                  as={CardExpiryElement}
                  hasFocus={stripeFocus === 'cardExpiry'}
                  isDisabled={isSubmitting}
                  onBlur={onStripeBlur}
                  onChange={onStripeChange}
                  onFocus={onStripeFocus}
                />
                <FormErrorMessage>
                  {errors.cardExpiry?.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={!!errors.cardCvc}>
                <FormLabel>Security code</FormLabel>
                <StripeInput
                  as={CardCvcElement}
                  hasFocus={stripeFocus === 'cardCvc'}
                  isDisabled={isSubmitting}
                  onBlur={onStripeBlur}
                  onChange={onStripeChange}
                  onFocus={onStripeFocus}
                />
                <FormErrorMessage>{errors.cardCvc?.message}</FormErrorMessage>
              </FormControl>
            </Grid>
            <Grid justifyContent="center" mt={8}>
              <FormControl isInvalid={!!errors.terms}>
                <Checkbox
                  colorScheme="msuGreen"
                  isDisabled={isSubmitting}
                  isInvalid={!!errors.terms}
                  {...register('terms')}
                >
                  {' '}
                  {/*  color={!!errors.terms ? "red.500" : undefined } */}I
                  accept the{' '}
                  <a href="/terms" target="_blank">
                    <Text textDecorationLine="underline">
                      terms and conditions
                    </Text>
                  </a>
                </Checkbox>
                <FormErrorMessage>{errors.terms?.message}</FormErrorMessage>
              </FormControl>
            </Grid>
            <Grid
              alignItems="center"
              gridGap={4}
              gridTemplateColumns="auto 1fr 160px"
              mt={8}
            >
              <Icon as={BiLock} w={8} h={8} />
              <Text size="xl">
                <Text as="span" fontWeight="bold">{`Safe & Secure`}</Text> SSL
                Encripted
              </Text>
              <img src={stripeImg} alt="Powered by Stripe" />
            </Grid>
            <Button
              color="msuGreen.500"
              colorScheme="aqua"
              isDisabled={!stripe || !elements}
              isLoading={isSubmitting}
              type="submit"
            >
              Submit order
            </Button>
          </Grid>
        </Flex>
      )}
    </form>
  )
}
